import Vue from 'vue'
import Vuex from 'vuex'
import SecureLS from 'secure-ls'
import system_data from './module/system'
import form_rules from './module/form_rules'
import authentication from './module/authentication'
import users from './module/user'
import branch from './module/data/branch'
import transaction_months from './module/data/transaction_months'
import members_information from './module/data/members_information'
import bank_depositories from './module/data/bank_depositories'
import deposit_slips from './module/data/deposit_slips'
import payments from './module/data/payments'
import db_file from './module/data/db_file'
import members_payment from './module/data/members_payment'
import district from './module/data/district'
import district_officers_history from './module/data/district_officers_history'
import cash_flow from './module/data/cash_flow'
import accomplishment from './module/data/accomplishment'
import inventory_particulars from './module/data/inventory_particulars'
import inventory_report from './module/data/inventory_report'
import inventory from './module/data/inventory'
import list_of_partnerships from './module/data/list_of_partnerships'
import request from './module/data/request'
import request_data from './module/data/request_data'
import cash_vouchers from './module/data/cash_vouchers'
import induction_histories from './module/data/induction_histories'
import induction_history_payments from './module/data/induction_history_payments'
import montly_meetings from './module/data/montly_meetings'
import induction_tickets from './module/data/induction_tickets'
import induction_tickets_payments from './module/data/induction_tickets_payments'
import createPersistedState from 'vuex-persistedstate';

const ls = new SecureLS({
  isCompression: false,
})
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState(
    {
      key: 'GGC-FOUNDATION',
      paths: ['authentication'],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    },
  )],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    system_data,
    form_rules,
    authentication,
    users,
    branch,
    transaction_months,
    members_information,
    bank_depositories,
    deposit_slips,
    payments,
    db_file,
    members_payment,
    district,
    district_officers_history,
    cash_flow,
    accomplishment,
    inventory_particulars,
    inventory_report,
    inventory,
    list_of_partnerships,
    request,
    request_data,
    cash_vouchers,
    induction_histories,
    induction_history_payments,
    montly_meetings,
    induction_tickets,
    induction_tickets_payments,
  }
})
