import axios from 'axios'

axios.defaults.withCredentials = true

const state = {
  id: 0,
  month_of: 'NA',
  year: 'NA',
  month_start: 0,
  month_end: 'NA',
}
const mutations = {
  set_id(state, id) {
    state.id = id
  },
  set_month_of(state, month_of) {
    state.month_of = month_of
  },
  set_year(state, year) {
    state.year = year
  },
  set_month_start(state, month_start) {
    state.month_start = month_start
  },
  set_month_end(state, month_end) {
    state.month_end = month_end
  },
}

const getters = {
  id: state => state.id,
  month_of: state => state.month_of,
  year: state => state.year,
  month_start: state => state.month_start,
  month_end: state => state.month_end,
}
const actions = {
  start_new_transaction_month({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/new-transaction-month',data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  retrieve_current_transaction_month({commit, dispatch, rootGetters}, month_of_id) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/current-transaction-month', {
        month_of_id: month_of_id
      })
        .then(response => {
          commit('set_id', response.data.id)
          commit('set_month_of', response.data.month_of)
          commit('set_year', response.data.year)
          commit('set_month_start', response.data.month_start)
          commit('set_month_end', response.data.month_end)
          dispatch('authentication/change_month', response.data.month_of, {root: true})
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  retrieve_transaction_month({commit, dispatch, rootGetters}, month_of_id) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/transaction-month')
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  retrieve_all_transaction_year({commit, dispatch, rootGetters}, month_of_id) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/transaction-year')
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
}
