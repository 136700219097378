import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import axios from "axios";
import VueCroppie from 'vue-croppie';
import Vue from 'vue';
import VueCarousel from 'vue-carousel';
import 'croppie/croppie.css' // import the croppie css manually

Vue.use(VueCroppie)
Vue.use(VueCarousel)

Vue.config.productionTip = false
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')

router.beforeResolve((to, from, next) => {
  document.title = to.meta.title
  const reqAuth = to.matched.some(record => record.meta.requiresAuth);
  const reqVisitor = to.matched.some(record => record.meta.requiresVisitor);

  const reqAdmin = to.matched.some(record => record.meta.requiresAdmin);
  const reqBranchManager = to.matched.some(record => record.meta.requiresBranchManager);
  const reqUser = to.matched.some(record => record.meta.reqUser);
  const requiresMerchant = to.matched.some(record => record.meta.requiresMerchant);

  const loginQuery = {path: '/'};
  const dashboard = {path: '/dashboard'};
  const not_found = {path: '/error-404'};

  if (reqAuth) {
    if (store.getters["authentication/authenticated"]) {
      if (reqAdmin && reqBranchManager) {
        if (store.getters["authentication/position"] === 'ADMIN'
          || store.getters["authentication/position"] === 'BRANCH MANAGER'
          || store.getters["authentication/position"] === 'MERCHANT PARTNER') {
          next();
        } else {
          next(not_found);
        }
      }else if (requiresMerchant) {
        if (store.getters["authentication/position"] === 'MERCHANT PARTNER') {
          next();
        } else {
          next(not_found);
        }
      } else if (reqAdmin) {
        if (store.getters["authentication/position"] === 'ADMIN') {
          next();
        } else {
          next(not_found);
        }
      } else if (reqBranchManager) {
        if (store.getters["authentication/position"] === 'BRANCH MANAGER') {
          next();
        } else {
          next(not_found);
        }
      } else if (reqUser) {
        if (store.getters["authentication/position"] === 'USER') {
          next();
        } else {
          next(not_found);
        }
      } else {
        next();
      }
    } else {
      next(loginQuery);
    }
  } else if (reqVisitor) {
    if (store.getters["authentication/authenticated"]) {
      if (store.getters["authentication/authenticated"]) {
        next(dashboard);
      } else {
        next(loginQuery);
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    switch (error.response.status) {
      case 401: // Not logged in
      case 419: // Session expired
      case 503: // Down for maintenance
        // Bounce the user to the login screen with a redirect back
        if (store.getters["authentication/authenticated"]) {
          store.commit('authentication/set_authenticated', false)
          store.dispatch('authentication/logout_credentials', {id_no: store.getters["authentication/id_no"]}, {root: true});
        }
        router.push({path: '/'})
        break;
      case 500:
        return Promise.reject(error);
        break;
      default:
        // Allow individual requests to handle other errors
        return Promise.reject(error);
    }
  }
)
