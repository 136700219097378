<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="400"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/main-logo.png')"
          max-height="80px"
          max-width="80px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h4 class="text--primary">
            GOODLIFE FOUNDATION
          </h4>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-link
        title="Dashboard"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiHomeOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="Cash Flow"
        :to="{ name: 'cash-flow' }"
        :icon="icons.mdiCashPlus"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="Deposit Slip"
        :to="{ name: 'new-deposit-slip' }"
        :icon="icons.mdiBankTransfer"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="Payments"
        :to="{ name: 'payments' }"
        :icon="icons.mdiCashMultiple"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="Load Inventories"
        :to="{ name: 'load-inventories' }"
        :icon="icons.mdiCubeScan"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="Assign Inventories"
        :to="{ name: 'assign-inventories' }"
        :icon="icons.mdiClipboardArrowDownOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="Inventory Report"
        :to="{ name: 'inventory-report' }"
        :icon="icons.mdiClipboardArrowDownOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="List of Request Not Yet Released"
        :to="{ name: 'request-not-yet-released' }"
        :icon="icons.mdiMessageBulletedOff"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="Create Cash Voucher"
        :to="{ name: 'create-voucher' }"
        :icon="icons.mdiAccountCash"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="Cash Voucher Report"
        :to="{ name: 'cash-voucher-report' }"
        :icon="icons.mdiFileChart"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="Assign Appointees"
        :to="{ name: 'assign-appointees' }"
        :icon="icons.mdiTransitTransfer"
      ></nav-menu-link>

      <nav-menu-section-title
        v-if="position!='USER' && position!='MERCHANT PARTNER'"
        title="USER INTERFACE"></nav-menu-section-title>
      <!--        Register-->
      <nav-menu-link
        v-if="position==='BRANCH MANAGER' && position!='MERCHANT PARTNER'"
        title="Request"
        :icon="icons.mdiChat"
        :to="{ name: 'new-request' }"
      ></nav-menu-link>
      <nav-menu-group
        v-if="position!='USER' && position!='MERCHANT PARTNER'"
        title="Register"
        :icon="icons.mdiPencilOutline"
      >
        <nav-menu-link
          v-if="position==='ADMIN'"
          title="Inductions"
          :to="{ name: 'register-inductions' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position==='ADMIN'"
          title="Tickets"
          :to="{ name: 'register-tickets' }"
        ></nav-menu-link>
        <nav-menu-link
          title="New Membership"
          :to="{ name: 'new-membership' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position==='BRANCH MANAGER'&& position!='MERCHANT PARTNER'"
          title="New Accomplishment"
          :to="{ name: 'new-accomplishment' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position==='BRANCH MANAGER'"
          title="New Monthly Meeting"
          :to="{ name: 'new-monthly-meeting' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position==='BRANCH MANAGER'||position==='ADMIN'"
          title="New Partnership"
          :to="{ name: 'new-partnership' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position==='ADMIN'"
          title="Inventory Particulars"
          :to="{ name: 'new-inventory-particulars' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position==='ADMIN'"
          title="New Bank Depositories"
          :to="{ name: 'new-bank-depositories' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position==='ADMIN'"
          title="New Sector/District/Club Details"
          :to="{ name: 'new-branch-details' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position==='ADMIN'"
          title="New User"
          :to="{ name: 'new-user' }"
        ></nav-menu-link>
      </nav-menu-group>
      <!--      Register End-->
      <!--      Search-->
      <nav-menu-group
        v-if="position!='USER' && position!='MERCHANT PARTNER'"
        title="Search"
        :icon="icons.mdiAccountSearchOutline"
      >
        <nav-menu-link
          title="Members"
          :to="{ name: 'search-members'}"
        ></nav-menu-link>
      </nav-menu-group>
      <!--      Search End-->
      <nav-menu-section-title
        v-if="position==='ADMIN' && position!='MERCHANT PARTNER'"
        title="VIEW INTERFACE"></nav-menu-section-title>
      <!--      Bank Depository-->
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="Bank Depository"
        :to="{ name: 'list-of-bank-depositories'}"
        :icon="icons.mdiBankOutline"
      ></nav-menu-link>
      <!--      Bank Depository End-->
      <!--      Remittances-->
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="Remittances"
        :to="{ name: 'remittances'}"
        :icon="icons.mdiCash100"
      ></nav-menu-link>
      <!--      Remittances End-->
      <nav-menu-section-title
        v-if="position!='USER' && position!='MERCHANT PARTNER'"
        title="REPORTS INTERFACE"></nav-menu-section-title>
      <nav-menu-link
        v-if="position!='USER' && position!='MERCHANT PARTNER'"
        title="Accomplishment Pointing System Report"
        :to="{ name: 'accomplishment-pointing-report-per' }"
        :icon="icons.mdiFileChart"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='USER' && position!='MERCHANT PARTNER'"
        title="Accomplishment Report"
        :to="{ name: 'accomplishment-report-per' }"
        :icon="icons.mdiFileChart"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='USER' && position!='MERCHANT PARTNER'"
        title="Monthly Meeting Report"
        :to="{ name: 'monthly-meeting-report-per' }"
        :icon="icons.mdiFileChart"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="List of Inducted"
        :to="{ name: 'list-of-inducted' }"
        :icon="icons.mdiAccountGroupOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="List of Tickets"
        :to="{ name: 'list-of-tickets' }"
        :icon="icons.mdiAccountGroupOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="Monthly Collection of Inducted"
        :to="{ name: 'monthly-collection-of-inducted' }"
        :icon="icons.mdiAccountGroupOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="Monthly Collection of Tickets"
        :to="{ name: 'monthly-collection-of-tickets' }"
        :icon="icons.mdiAccountGroupOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='USER'&& position!='MERCHANT PARTNER'"
        title="List of Partners"
        :to="{ name: 'list-of-partners' }"
        :icon="icons.mdiAccountGroupOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='USER'&& position!='MERCHANT PARTNER'"
        title="List of Members"
        :to="{ name: 'list-of-new-members' }"
        :icon="icons.mdiAccountGroupOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="List of Floating Members"
        :to="{ name: 'list-of-floating-members' }"
        :icon="icons.mdiAccountCircle"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='USER'&& position!='MERCHANT PARTNER'"
        title="List of Current Officers By District"
        :to="{ name: 'list-of-members-by-district' }"
        :icon="icons.mdiClipboardAccountOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='USER'&& position!='MERCHANT PARTNER'"
        title="List of Due Members"
        :to="{ name: 'list-of-due-members' }"
        :icon="icons.mdiAccountClock"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='USER'&& position!='MERCHANT PARTNER'"
        title="List of Paid Due Members"
        :to="{ name: 'list-of-paid-due-members' }"
        :icon="icons.mdiAccountMultipleCheck"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='USER'&& position!='MERCHANT PARTNER'"
        title="List of Unpaid Members"
        :to="{ name: 'list-of-unpaid-members' }"
        :icon="icons.mdiAccountAlertOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='USER'&& position!='MERCHANT PARTNER'"
        title="List of Paid Members"
        :to="{ name: 'list-of-paid-members' }"
        :icon="icons.mdiAccountCashOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='USER'"
        title="List of Verified Members By Merchant"
        :to="{ name: 'list-of-verified-members-by-merchant' }"
        :icon="icons.mdiAccountCashOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="List of Clubs"
        :to="{ name: 'list-of-clubs' }"
        :icon="icons.mdiLightbulbGroupOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="List of Districts"
        :to="{ name: 'list-of-districts' }"
        :icon="icons.mdiCharity"
      ></nav-menu-link>
    </v-list>
    <!--    <a-->
    <!--      href="https://themeselection.com/products/materio-vuetify-vuejs-admin-template"-->
    <!--      target="_blank"-->
    <!--      rel="nofollow"-->
    <!--    >-->
    <!--      <v-img-->
    <!--        :src="require(`@/assets/images/pro/upgrade-banner-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"-->
    <!--        alt="upgrade-banner"-->
    <!--        transition="scale-transition"-->
    <!--        class="upgrade-banner mx-auto"-->
    <!--        max-width="230"-->
    <!--      ></v-img>-->
    <!--    </a>-->
  </v-navigation-drawer>
</template>

<script>
  // eslint-disable-next-line object-curly-newline
  import {
    mdiClipboardAccountOutline,
    mdiHomeOutline,
    mdiAlphaTBoxOutline,
    mdiAccountCircle,
    mdiEyeOutline,
    mdiCreditCardOutline,
    mdiTable,
    mdiFileOutline,
    mdiFormSelect,
    mdiAccountCogOutline,
    mdiFolderPlusOutline,
    mdiPencilOutline,
    mdiChat,
    mdiAccountSearchOutline,
    mdiCashMultiple,
    mdiBankOutline,
    mdiCurrencyPhp,
    mdiCurrencyUsdOff,
    mdiCashRefund,
    mdiMonitor,
    mdiAccountClock,
    mdiAccountMultipleCheck,
    mdiCash100,
    mdiScatterPlotOutline,
    mdiAccountCashOutline,
    mdiMessageBulletedOff,
    mdiShieldAccount,
    mdiAccountGroupOutline,
    mdiClipboardClockOutline,
    mdiCashPlus,
    mdiReload,
    mdiShareVariantOutline,
    mdiLightbulbGroupOutline,
    mdiCreditCardCheckOutline,
    mdiCashRemove,
    mdiCashCheck,
    mdiBookmarkMultipleOutline,
    mdiChartBarStacked,
    mdiChartMultiline,
    mdiFinance,
    mdiBankTransfer,
    mdiCashMinus,
    mdiReceipt,
    mdiPaperRollOutline,
    mdiPrinter,
    mdiAccountAlertOutline,
    mdiCharity,
    mdiFileChart,
    mdiTransitTransfer,
    mdiCubeScan,
    mdiClipboardArrowDownOutline,
    mdiAccountCash,
  } from '@mdi/js'
  import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
  import NavMenuGroup from './components/NavMenuGroup.vue'
  import NavMenuLink from './components/NavMenuLink.vue'
  import {mapActions, mapGetters} from "vuex";
  import moment from "moment";

  export default {
    components: {
      NavMenuSectionTitle,
      NavMenuGroup,
      NavMenuLink,
    },
    computed: {
      ...mapGetters('authentication', ['position']),
    },
    props: {
      isDrawerOpen: {
        type: Boolean,
        default: null,
      },
    },
    setup() {
      return {
        interval: {},
        today_time: 0,
        icons: {
          mdiHomeOutline,
          mdiAccountCircle,
          mdiClipboardAccountOutline,
          mdiAccountClock,
          mdiAccountMultipleCheck,
          mdiAlphaTBoxOutline,
          mdiEyeOutline,
          mdiCreditCardOutline,
          mdiTable,
          mdiFileOutline,
          mdiFormSelect,
          mdiAccountCogOutline,
          mdiFolderPlusOutline,
          mdiPencilOutline,
          mdiChat,
          mdiAccountSearchOutline,
          mdiCashMultiple,
          mdiBankOutline,
          mdiCurrencyPhp,
          mdiCurrencyUsdOff,
          mdiCashRefund,
          mdiMonitor,
          mdiCash100,
          mdiScatterPlotOutline,
          mdiAccountCashOutline,
          mdiMessageBulletedOff,
          mdiShieldAccount,
          mdiAccountGroupOutline,
          mdiClipboardClockOutline,
          mdiCashPlus,
          mdiReload,
          mdiShareVariantOutline,
          mdiLightbulbGroupOutline,
          mdiCreditCardCheckOutline,
          mdiCashRemove,
          mdiCashCheck,
          mdiBookmarkMultipleOutline,
          mdiChartBarStacked,
          mdiChartMultiline,
          mdiFinance,
          mdiBankTransfer,
          mdiCashMinus,
          mdiReceipt,
          mdiPaperRollOutline,
          mdiPrinter,
          mdiCharity,
          mdiFileChart,
          mdiTransitTransfer,
          mdiCubeScan,
          mdiClipboardArrowDownOutline,
          mdiAccountCash,
          mdiAccountAlertOutline,
        },
      }
    },
    mounted() {
      this.interval = setInterval(this.todays_time, 1000);
    },
    beforeDestroy() {
      clearInterval(this.interval)
    },
    methods: {
      ...mapActions('authentication', ['change_teller_already_cut_off']),

      todays_time() {
        this.today_time = parseInt(moment().format('k'))
        if (this.today_time >= 17) {
          this.change_teller_already_cut_off(true)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .app-title {
    font-size: 1.25rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
  }

  // ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
  .app-logo {
    transition: all 0.18s ease-in-out;

    .v-navigation-drawer--mini-variant & {
      transform: translateX(-4px);
    }
  }

  @include theme(app-navigation-menu) using($material) {
    background-color: map-deep-get($material, 'background');
  }

  .app-navigation-menu {
    .v-list-item {
      &.vertical-nav-menu-link {
        ::v-deep .v-list-item__icon {
          .v-icon {
            transition: none !important;
          }
        }
      }
    }
  }

  // You can remove below style
  // Upgrade Banner
  .app-navigation-menu {
    .upgrade-banner {
      position: absolute;
      bottom: 13px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
</style>
